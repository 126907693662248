<template>
  <div class="w-100 mb-3">
    <div class="row justify-content-center" v-if="!isLoading">
      <div
        :class="
          isDesktopView
            ? `col-${12 / pro.productsPerPage}`
            : `col-${12 / pro.productsPerPageMobile}`
        "
        class="mb-3"
        v-for="(product, index) in getDetailListProducts"
        :key="index + Math.random()"
      >
        <!-- style="max-width: 211px" -->
        <div
          class="card shadow-sm px-3 d-flex flex-column h-100 mx-auto position-relative"
          :style="
            getCardBackground(pro.style) +
            getCardBorderCSS(pro.style) +
            getPagePadding(pro.style) +
            getFontFamily(pro.style) +
            ListProductMaxWidth(
              isDesktopView ? pro.productsPerPage : pro.productsPerPageMobile
            )
          "
        >
          <div>
            <div
              v-if="
                getProductInfoDetail(pro.useProductVariant, product, 'image')
              "
              style="height: 100%"
            >
              <div
                v-if="
                  checkFileType(
                    getProductInfoDetail(
                      pro.useProductVariant,
                      product,
                      'image'
                    )
                  ) == 'video'
                "
                :style="ListImageAspectRatio(pro.style)"
              >
                <video
                  ref="videoRef"
                  :src="`${getProductInfoDetail(
                    pro.useProductVariant,
                    product,
                    'image'
                  )}`"
                  :style="
                    getImageFit(pro.style) + getImageTopBorderCSs(pro.style)
                  "
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                  "
                  controls
                ></video>
              </div>
              <div v-else :style="ListImageAspectRatio(pro.style)">
                <LazyImage
                  v-if="
                    getProductInfoDetail(
                      pro.useProductVariant,
                      product,
                      'image'
                    ).startsWith('upload')
                  "
                  :src="
                    '/' +
                    getProductInfoDetail(
                      pro.useProductVariant,
                      product,
                      'image'
                    )
                  "
                  alt=""
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                  "
                  :style="
                    getImageFit(pro.style) + getImageTopBorderCSs(pro.style)
                  "
                />
                <LazyImage
                  v-else
                  :src="
                    getProductInfoDetail(
                      pro.useProductVariant,
                      product,
                      'image'
                    )
                  "
                  alt=""
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                  "
                  :style="
                    getImageFit(pro.style) + getImageTopBorderCSs(pro.style)
                  "
                />
              </div>
            </div>
            <LazyImage
              v-else
              :src="'/images/image_default_placeholder.png'"
              alt=""
              :style="getImageTopBorderCSs(pro.style)"
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="mt-2 flex-grow-1" :style="getPaddingCss(pro.style)">
            <div class="d-flex flex-column h-100">
              <!-- Product Title -->
              <div class="px-3">
                <p :style="getTitleStyles(pro.style)">
                  {{
                    getProductInfoDetail(
                      pro.useProductVariant,
                      product,
                      "title"
                    )
                  }}
                </p>

                <!-- Rating Div start -->
                <div
                  v-if="product.rating && isJudgeMeInstalled"
                  class="Review px-3"
                  v-html="product.rating"
                  :style="getPriceColor(pro.style)"
                ></div>
                <!-- Rating Div end -->

                <div
                  class="d-flex align-items-center"
                  :style="'justify-content:' + pro.style.textAlign + ';'"
                >
                  <p
                    v-if="product.price && !isHidePrice"
                    :style="getPriceColor(pro.style)"
                    class="mr-2"
                  >
                    <span
                      v-if="CurrencySign && currencyPos == 'left'"
                      :class="currencySpace ? 'mr-1' : null"
                      >{{ CurrencySign }}</span
                    >{{
                      getProductInfoDetail(
                        pro.useProductVariant,
                        product,
                        "price"
                      )
                    }}<span
                      v-if="CurrencySign && currencyPos == 'right'"
                      :class="currencySpace ? 'ml-1' : null"
                      >{{ CurrencySign }}</span
                    >
                  </p>
                  <p
                    v-if="
                      product.compare_at_price &&
                      product.price !== product.compare_at_price &&
                      !isHidePrice
                    "
                    :style="getPriceColor(pro.style)"
                  >
                    <s>
                      <span
                        v-if="CurrencySign && currencyPos == 'left'"
                        :class="currencySpace ? 'mr-1' : null"
                        >{{ CurrencySign }}</span
                      >{{ product.compare_at_price
                      }}<span
                        v-if="CurrencySign && currencyPos == 'right'"
                        :class="currencySpace ? 'ml-1' : null"
                        >{{ CurrencySign }}</span
                      >
                    </s>
                  </p>
                </div>
              </div>
              <!-- Product Price , features & buy button -->
              <div
                class="d-flex flex-column justify-content-between flex-grow-1"
              >
                <div class="">
                  <div
                    v-if="!pro.hideDescription && !pro.showHeirarchyDesciption"
                  >
                    <div
                      class="d-flex align-items-center mt-1"
                      :style="
                        limitProductDescription(
                          pro.limitDescription,
                          product.readMore
                        )
                      "
                    >
                      <div
                        v-if="
                          pro.useBulletDescription &&
                          product.bullet_description &&
                          product.bullet_description.length
                        "
                      >
                        <div
                          v-for="(
                            bulletPoint, index2
                          ) in product.bullet_description"
                          :key="index2 + Math.random()"
                        >
                          <div
                            class="d-flex align-items-start mt-1"
                            :style="getBulletStyles(pro.style)"
                          >
                            <div class="mr-3">
                              <i class="fa" :class="pro.selectedBulletIcon"></i>
                            </div>
                            <span style="text-align: start">{{
                              bulletPoint
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="w-100"
                        v-else
                        v-html="product.description"
                      ></div>
                      <!-- <div class="w-100 "  v-if="!pro.hideDescription" v-html="product.description"></div> -->
                    </div>
                    <div
                      class=""
                      v-if="
                        pro.limitDescription &&
                        product.description &&
                        product.description.split(' ').join('').length > 150
                      "
                    >
                      <button
                        class="btn"
                        :class="product.readMore ? 'mt-2' : 'mt-2'"
                        :style="getPriceColor(pro.style)"
                        @click="product.readMore = !product.readMore"
                      >
                        <span v-if="product.readMore">{{
                          translateText("Less info")
                        }}</span>
                        <span v-else>{{ translateText("More info") }} </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-3" v-if="product.detail_link">
                  <div
                    class="pb-2"
                    v-if="pro.useProductVariant && product && product.variants"
                  >
                    <VaraintSelectComp :pro="pro" :product="product" />
                  </div>
                  <button
                    v-if="pro.useAddToCart"
                    :style="getProductButtonCss(pro.style)"
                    :disabled="disableAddtoCartBtn"
                    @click="AddToCart(product, index)"
                  >
                    <span v-if="!product.loading">{{
                      pro.useAddToCartText || "Add to Cart"
                    }}</span>
                    <div
                      v-else
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                  <button
                    v-else
                    :style="getProductButtonCss(pro.style)"
                    @click="goToURLClicked(product.detail_link)"
                  >
                    {{ pro.buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center" v-else>
      <div
        v-for="index in 3"
        :key="index"
        class="card mx-3 py-2 shadow-sm px-3 w-100"
      >
        <div>
          <div style="height: 100%">
            <div>
              <b-skeleton-img></b-skeleton-img>
            </div>
          </div>
        </div>
        <div
          class="mt-2 flex-grow-1"
          style="text-align: center; padding: 10px 0px"
        >
          <div class="d-flex flex-column h-100">
            <div class="px-3">
              <b-skeleton></b-skeleton>
            </div>
            <div class="d-flex flex-column justify-content-between flex-grow-1">
              <div class="mt-3 d-flex justify-content-center">
                <b-skeleton type="button"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3">
      <!-- <p v-if="coupon_code && isLeadEmailExist" :style="getMoreOptionStyles(pro.style) + 'font-size:22px!important'">
       Your coupon code (write it down): {{coupon_code}}
      </p>
      <p v-if="coupon_code && isLeadEmailExist" class="">
        Gummies are free and no purchase is necessary. If you are ordering other products, simply type this code into the coupon box at checkout and your free bag will be added to the cart. If you only want the free gummies, visit the Mood Gummy bag page and type the code in to go directly to checkout - you’ll only pay for the shipping (even the shipping is free if you spend $75).
      </p> -->
      <p
        :style="getMoreOptionStyles(pro.style) + 'font-size:22px!important'"
        class="mt-3"
      >
        Products Selected For You
      </p>
      <p
        class="m-0 p-0"
        style="
          color: rgb(0, 0, 0);
          text-align: center;
          padding: 10px 0px;
          margin: 0px;
          opacity: 1;
          transform: rotate(0deg);
          line-height: 1;
          font-weight: normal;
          font-style: normal;
          text-decoration: unset;
          font-size: 14px;
          font-family: Poppins;
        "
      >
        Given your chosen needs, THC content, and product type, we have matched
        you with:
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import cssFunctionMixin from "../../customize/mixin/cssFunction.js";
import { mapGetters } from "vuex";
// import ProductTableClient from "../quizPreviewComp/ProductTableClient.vue";
// import ProductTableClientUpdate from "../quizPreviewComp/ProductTableClientUpdate.vue";
export default {

  components:{
  VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../VaraintSelectComp.vue"
      )
},
  props: {
    pro: Object,
    isDesktopView: Boolean,
    pageDesign: Object,
    isRanked: Boolean,
    apiToken: String,
  },

  mixins: [cssFunctionMixin],

  data() {
    return {
      isLoading: true,
      productsWithoutSku: [],
      chemicalProducts: [],
      isJudgeMeInstalledInQuiz: false,
      currentAddToCartProductIndex: null,
      coupon_code: null,
    };
  },

  methods: {
    AddToCart(product, index) {
      this.products[index].loading = true;
      this.currentAddToCartProductIndex = index;
      let data = {
        variant_id: product.variant_id,
        quiz_key: this.apiToken,
      };
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        data.variant_id = product.selectedVariant.external_id;
      }

      try {
        window.parent.postMessage(JSON.stringify(data), "*");
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateText("An error occurred. Please try again."),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      }
      // finally {
      //   this.products[index].loading = false;
      // }
    },
    // variant MEthods
    getSingleProductImage(product) {
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        if (product.selectedVariant.image) {
          return product.selectedVariant.image;
        } else {
          return product.image;
        }
      } else {
        return product.image;
      }
    },
    getSingleProductTitle(product) {
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        return product.selectedVariant.title;
      } else {
        return product.title;
      }
    },
    getSingleProductPrice(product) {
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        return product.selectedVariant.price;
      } else {
        return product.price;
      }
    },

    getProductInfoDetail(isProductVarient, product, value) {
      if (isProductVarient) {
        return product.selectedVariant && product.selectedVariant != ""
          ? product.selectedVariant[value]
            ? product.selectedVariant[value]
            : product[value]
          : product[value];
      } else {
        return product[value];
      }
    },

    // Varient Method End

    updateProductVariant(product) {
      const selectedVariant = product.variants.find(
        (variant) => variant.title === product.selectedVariant.title
      );
      product.title = selectedVariant.title;
      product.price = selectedVariant.price;
      product.image = selectedVariant.image;
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    showImage(image) {
      if (image == "/images/image_placeholder.png") return false;
      return true;
    },

    getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getPriceColor(property) {
      return (
        "color:" +
        property.priceColor +
        ";text-align:" +
        property.textAlign +
        ";"
      );
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property, isDesktopView) {
  const getPaddingValue = (desktopValue, mobileValue) =>
    isDesktopView || mobileValue === undefined ? desktopValue : mobileValue;

  const paddingTop = getPaddingValue(property.pagePaddingTop, property.pageMobilePaddingTop);
  const paddingRight = getPaddingValue(property.pagePaddingRight, property.pageMobilePaddingRight);
  const paddingBottom = getPaddingValue(property.pagePaddingBottom, property.pageMobilePaddingBottom);
  const paddingLeft = getPaddingValue(property.pagePaddingLeft, property.pageMobilePaddingLeft);

  return `; text-align: ${property.textAlign}; padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px; `;
},
    getImageTopBorderCSs(property) {
      return (
        " border-top-left-radius:" +
        property.borderRadius +
        "px; border-top-right-radius:" +
        property.borderRadius +
        "px ; "
      );
    },
    getImageRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = "";
      } else if (property.imageRatio === "potrait") {
        imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`;
      } else {
        imageRatioCSS = `position:absolute; width:100%; height:66%; margin:auto; top:0; bottom:0;`;
      }

      return " width: 100% " + ";" + "height: 150px" + ";" + imageRatioCSS;
    },
    getImageFit(property) {
      let imageFitCSS;
      if (property.imageFit === "Fit") {
        imageFitCSS = "object-fit: contain" + ";";
      } else if (property.imageFit === "Fill") {
        imageFitCSS = "object-fit: fill" + ";";
      }
      return imageFitCSS;
    },
    getMoreOptionText(value) {
      if (value) {
        return value;
      } else {
        return "More options";
      }
    },
    // goToURLClicked(url){
    //     if(url !== ''){
    //         window.open(url);
    //     }
    // },

    displayProductsLength(element) {
      let totalProducts;
      if (this.isDesktopView) {
        totalProducts = element.productsPerPage * element.rows;
      } else {
        totalProducts = element.productsPerPageMobile * element.rows;
      }
      return totalProducts;
    },
    goToURLClicked(url) {
      let URL = url;
      let pattern = /^((http|https|ftp):\/\/)/;
      if (URL) {
        if (!pattern.test(URL)) {
          URL = "http://" + URL;
        }
        window.open(URL);
      }
    },

    async getScoreForChemicalClient() {
      this.isLoading = true;
      let selectedOptionIds = [];
      this.getSelectedOptions.questionAnswer[0].selectedOption.forEach(
        (option) => {
          selectedOptionIds.push(option.id);
        }
      );
      await axios
        .post("/api/getCannabinoidData", {
          api_token: this.apiToken,
          optionIds: selectedOptionIds,
        })
        .then((resp) => {
          this.chemicalProducts = resp.data.data.products;
          this.coupon_code = resp.data.data.coupon_code;
        })
        .catch((err) => {
          throw err;
        });
      this.isLoading = false;
    },
  },
  created() {
    this.getScoreForChemicalClient();
  },
  computed: {
    disableAddtoCartBtn() {
      return typeof this.currentAddToCartProductIndex == "number"
        ? true
        : false;
    },

    CurrentPageType() {
      if (this.products.length) return "resultPage";
      return "noResultPage";
    },
    isNoResultSrcPresent() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.pageDesign.imageBlock,
          "noResultSrc"
        )
      )
        return true;
      return false;
    },

    isJudgeMeInstalled() {
      return this.isJudgeMeInstalledInQuiz || this.isJudgeMeInstalledInQuiz == 1
        ? true
        : false;
    },
    isAddToCartActive() {
      if (typeof this.getPreviewSettings.add_to_cart !== "undefined") {
        return this.getPreviewSettings.add_to_cart;
      } else {
        return false;
      }
    },

    AnimationSettings() {
      return this.getAnimationSetting;
    },
    AmimationType() {
      if (
        this.AnimationSettings &&
        typeof this.AnimationSettings !== "undefined" &&
        this.AnimationSettings.show
      ) {
        if (this.AnimationSettings.direction == "RTL") {
          if (this.animationType == "SlideIn") return "slideRTL";
          return "slideLTR";
        } else {
          if (this.animationType == "SlideIn") return "slideLTR";
          return "slideRTL";
        }
      } else {
        return null;
      }
    },
    getFirstProduct() {
      if (this.products.length > 0) {
        return this.products[0];
      } else {
        return {};
      }
    },
    getDetailListProducts() {
      return this.chemicalProducts;
    },

    showLeftImage() {
      return (
        (this.pageDesign.imageBlock.noResultSrc !== "" &&
          this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.pageDesign.imageBlock.noResultSrc !== "" &&
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getSelectedOptions",
      "getPreviewSettings",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
    ]),
    getLeadObjfromVuex() {
      return this.getSavedLeadID;
    },
    isLeadEmailExist() {
      return this.getLeadObjfromVuex.email &&
        this.getLeadObjfromVuex.email !== ""
        ? true
        : false;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (this.CurrentPageType == "noResultPage") {
        if (
          this.isNoResultSrcPresent &&
          this.pageDesign.imageBlock.noResultSrc !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.pageDesign.imageBlock.src !== "") return true;
        return false;
      }
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      if (this.pageDesign.pageType == "resultPage") return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    CurrencySign() {
      return this.getPreviewSettings.currencySign.sign;
    },
    isHidePrice() {
      return this.getPreviewSettings.hidePrice;
    },
    getProductArrayIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },

    currencyPos() {
      const index = this.getProductArrayIndex;
      if (index) {
        if (this.pageDesign.blocksArray[index].style.Currencyposition) {
          return this.pageDesign.blocksArray[index].style.Currencyposition;
        }
        return "left";
      }
      return null;
    },
    currencySpace() {
      if (this.CurrencySign == "RON") return true;
      return false;
    },
  },

  watch: {},
};
</script>
<style scoped>
.images-size label {
  color: #b3afb6;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 5px !important;
  padding-right: 2rem;
}
.custom-select-aanswer.result-select {
  border: none !important;
  width: 100%;
  padding-left: 0px;
}
.result-select {
  padding-left: 35px;
  background: #f4f3f5;
  height: inherit;
  border: none !important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}
.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
  width: 100%;
}
.sidebar {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.big-div {
  padding: 0px;
}

/* Carousel CSS  */
.ProductCarousel >>> .VueCarousel-pagination {
  height: 40px !important;
  visibility: hidden !important;
}
.ProductCarousel >>> .VueCarousel-navigation {
  position: absolute;
  top: 95%;
  left: 50%;
}

/* Judge Me Css */
.Review >>> .jdgm-prev-badge {
  display: block !important;
}

.Review >>> .jdgm-star {
  display: inline !important;
  text-decoration: none !important;
  padding: 0 4px 0 0 !important;
  margin: 0 !important;
  font-weight: bold;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
}
.Review >>> .jdgm-preview-badge .jdgm-star.jdgm-star {
  color: #eab304;
}

.Review >>> .jdgm-star.jdgm--on:before {
  font-family: FontAwesome;
  content: "\f005";
  color: #eab304;
}
.Review >>> .jdgm-star.jdgm--half:before {
  font-family: FontAwesome;
  content: "\f123";
  color: #eab304;
}
.Review >>> .jdgm-star.jdgm--off:before {
  font-family: FontAwesome;
  content: "\f006";
  color: #eab304;
}
</style>
